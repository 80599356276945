<template>
  <router-view/>


  <!-- 

   _______________________________________
       |,---"-----------------------------"---,|
       ||___    16 bit....................    ||
       ||====\ :HHHHHHHHHHHHHHHHHHHHHHHHHHH   ||
       ||=====):H c> xombie#              H   ||
       ||====/ :H                         H   ||
       ||"""   :H                         H   ||
       ||PORTFO:H                         H   ||
       ||      :HHHHHHHHHHHHHHHHHHHHHHHHHHH   ||
       ||_____,_________________________,_____||
       |)_____)-----.| /I\ATARI |.------(_____(|
     //"""""""|_____|=----------=|______|"""""""\
    // _| _| _| _| _| _| _| _| _| _| _| _| _| _| \
   // ___| _| _| _| _| _| _| _| _| _| _| _|  |  | \
  |/ ___| _| _| _| _| _| _| _| _| _| _| _| ______| \
  / __| _| _| _| _| _| _| _| _| _| _| _| _| _| ___| \
 / _| _| _| _| ________________________| _| _| _| _| \
|------"--------------------------------------"-------|
`-----------------------------------------------------'

  ╋╋╋╋╋╋╋╋╋┏┓
  ╋╋╋╋╋╋╋╋╋┃┃
  ┏┓┏┳━━┳┓┏┫┗━┳┳━━┓
  ┗╋╋┫┏┓┃┗┛┃┏┓┣┫┃━┫
  ┏╋╋┫┗┛┃┃┃┃┗┛┃┃┃━┫
  ┗┛┗┻━━┻┻┻┻━━┻┻━━┛

  Art by Przemek Borys
-->
</template>

<style lang="scss">



// Importing the font Inter from Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


#app {
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

</style>
