<template>
  <div class="home">
    <h1>This is a temporary homepage</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home'
}
</script>
