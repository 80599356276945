<template>
    <div id="container">
        <h1>404</h1>
        <h2>We couldn't <br class="newLine">find that page</h2>

        <router-link to="/">
            <button class="button is-primary is-outlined">Go home</button>
        </router-link>
    </div>
</template>

<style lang="scss" scoped>

.newLine{
    @media only screen and (min-width: 1000px) {
        display: none;
    }
}

h1{
    font-size:150pt;
    font-weight:bold;
    color:gray;
    margin-top:10vh;
    font-style:italic;

    @media only screen and (max-width: 1000px) {
        font-size:100pt;
    }
}

h2{
    font-size:18pt;
    color:white;
    margin-top:2vh;
    font-weight:bold;
    margin-bottom:4vh;
}


#container{
    background: #262626;

    height:100vh;
    width:100vw;

    position:fixed;
    top:0;bottom:0;
    left:0;right:0;

    color:white;
}

</style>

<script>
export default {
    name: "PageNotFound"
}
</script>

